@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .square {
        aspect-ratio: 1 / 1;
    }

    .blue-links a {
        @apply text-turquoise-100 underline
    }

    .input-number::-webkit-inner-spin-button, .input-number::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .tooltip-white {
        @apply bg-white rounded-xl drop-shadow-black-100 text-white font-gilroy-400 text-black opacity-100 max-w-[18rem] m-md:max-w-[30rem] t-sm:max-w-[40rem] line-clamp-4 m-md:line-clamp-6 t-sm:line-clamp-10 overflow-hidden py-1 px-3 z-100
    }

    .input-number[type=number] {
        -moz-appearance: textfield;
    }

    .transition-full {
        transition: all 0.5s linear;
    }

    .stroke-path-white path {
        @apply stroke-white
    }

    .stroke-path-grey-100 path {
        @apply stroke-grey-100
    }

    .stroke-path-grey path {
        @apply stroke-white-200
    }

    .stroke-path-green path {
        @apply stroke-green-100
    }

    .btn {
        @apply flex justify-center rounded-full transition-full font-gilroy-600 gap-2;
    }

    /*btn-green*/
    .btn-green {
        @apply bg-green-rgb-100 text-white
    }

    /*btn-turquoise*/
    .btn-turquoise {
        @apply bg-turquoise-100 text-white
    }

    .btn-turquoise:active {
        @apply text-blue-100 bg-white;
    }

    .btn-turquoise:disabled {
        @apply text-blue-400 bg-white-100 border-2 border-blue-400;
    }

    .btn-turquoise:hover:not(:disabled):not(:active) {
        @apply shadow-turquoise-100;
    }

    /*btn-turquoise-outline*/
    .btn-turquoise-outline {
        @apply bg-white text-turquoise-100 border-2 border-turquoise-100
    }

    .btn-turquoise-outline:active {
        @apply text-turquoise-100 bg-white border-white;
    }

    .btn-turquoise-outline:disabled {
        @apply text-turquoise-400 bg-white-100 border-2 border-blue-400;
    }

    .btn-turquoise-outline:hover:not(:disabled):not(:active) {
        @apply text-turquoise-100 shadow-turquoise-100;
    }

    /*btn-turquoise-light*/
    .btn-turquoise-light {
        @apply bg-turquoise-500 text-violet-200
    }

    .btn-turquoise-light svg {
        @apply fill-turquoise-100
    }

    .btn-turquoise-light:active {
        @apply text-violet-200 bg-white;
    }

    .btn-turquoise-light:disabled {
        @apply text-blue-400 bg-white-100 border-2 border-blue-400;
    }

    .btn-turquoise-light:hover:not(:disabled):not(:active) {
        @apply shadow-turquoise-100;
    }

    /*btn-grey*/
    .btn-grey {
        @apply bg-grey-100 text-white
    }

    .btn-grey svg {
        @apply fill-white
    }

    .btn-grey:active {
        @apply bg-blue-100;
    }

    /*btn-icon*/
    .btn-turquoise-icon {
        @apply bg-grey-800 text-white
    }

    .btn-turquoise-icon svg {
        @apply fill-turquoise-100
    }

    .font-gilroy-400 {
        font-family: 'Gilroy Regular', sans-serif;
        font-weight: 400;
    }

    .font-gilroy-500 {
        font-family: 'Gilroy Medium', sans-serif;
        font-weight: 500;
    }

    .font-gilroy-600 {
        font-family: 'Gilroy SemiBold', sans-serif;
        font-weight: 600;
    }

    .font-gilroy-700 {
        font-family: 'Gilroy Bold', sans-serif;
        font-weight: 700;
    }

    .font-gilroy-800 {
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
    }

    .font-gilroy-900 {
        font-family: 'Gilroy Black', sans-serif;
        font-weight: 900;
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }

        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }

}


@font-face {
    font-family: 'Gilroy Regular';
    src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy SemiBold';
    src: url('./assets/fonts/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy ExtraBold';
    src: url('./assets/fonts/Gilroy-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Black';
    src: url('./assets/fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}


*::-webkit-scrollbar,
html *::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
    background: rgba(130, 152, 173, 0.3);
}

*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
    background-color: #7E52CB;
    border: 6px solid #7E52CB;
    border-radius: 10px;
}

a {
    color: inherit;
    text-decoration: none;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

#root {
    width: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
}

.react-datepicker {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eaeaea;
    padding: 8px;
}

.react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
    margin: 0 8px;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #8654CC;
    color: #fff;
    border-radius: 4px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    display: none !important;
}

.react-datepicker__current-month {
    display: none;
}

.react-datepicker__week {
    display: flex;
    justify-content: space-between;
}

.react-datepicker__month {
    margin: 0px;
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
}

.react-datepicker__day-name {
    text-transform: uppercase;
    margin: 0px;
}

.react-datepicker__day {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 27px;
    height: 20px;
    margin: 0px;
}

.react-datepicker__day--selected {
    border: none;
}

.react-datepicker__input-container {
    display: none;
}

.react-datepicker__day:hover {
    background-color: #E6DCF4;
}